<template>
  <div class="my-flex-container-column">
    <el-row class="m-0" :gutter="20">
      <el-col :span="3">
        <el-button v-if="userCanEdit" @click="handleCreate" :edit-mode="true" type="text"
                   icon="el-icon-circle-plus-outline" :disabled="mergeMode">Добавить
        </el-button>
      </el-col>
      <el-col :span="3">
      <el-button @click="handleDownLoad" type="text"
                  :loading="loading"
                   icon="el-icon-download">Выгрузить
        </el-button>
      </el-col>
      <el-col v-if="userCanEdit" :span="3">
        <el-button @click="handleMergeMode" type="text" icon="el-icon-share">Объединить</el-button>
      </el-col>
      <el-col v-if="userCanEdit && mergeMode" :span="3">
        <el-button @click="handleMergeModeCancel" type="text" icon="el-icon-circle-close">Отменить
        </el-button>
      </el-col>
      <el-col :span="12">
        <pagination style="text-align: center"
                    :total-count="totalCount"
                    :page-size.sync="pageSize"
                    :current-page.sync="pageNumber"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"/>
      </el-col>
    </el-row>
    <div   class="my-flex-data-content">
      <el-table v-loading="loading" ref="table"
                style="width: 100%"
                height="100%"
                :data="psrType"
                :default-sort="{prop: 'name', order: 'ascending'}"
                @sort-change="handleSortChange">
        <el-table-column label="" width="50">
          <template slot-scope="scope">
            <el-button type="text" icon="el-icon-search" v-if="!mergeMode"
                       @click="handleView(scope.$index, scope.row)"></el-button>
            <el-checkbox v-if="mergeMode" v-model="scope.row.isMerging"
                         @change="handleIsMergingChange(scope.row)"></el-checkbox>
          </template>
        </el-table-column>
        <el-table-column prop="name"
                         label="Наименование"
                         sortable="custom"
                         min-width="30">
        </el-table-column>
        <el-table-column prop="description"
                         label="Описание"
                         sortable="custom"
                         min-width="30">
        </el-table-column>
        <el-table-column prop="registrationGroupCode"
                         label="Группа учета"
                         sortable="custom"
                         min-width="30">
        </el-table-column>
        <el-table-column prop="isAip"
                         label="СК-11"
                         width="80"
                         align="center"
                         header-align="center"
                         sortable="custom"
                         :formatter="boolRenderer">
        </el-table-column>
        <el-table-column v-if="userCanEdit" label="" width="50">
          <template slot-scope="scope">
            <el-button type="text" icon="el-icon-delete" :disabled="scope.row.isAip"
                       @click="handleDelete(scope.$index, scope.row)"></el-button>
          </template>
        </el-table-column>
      </el-table>

      <psr-type-modal @on-update="getPsrType()" @on-create="getPsrType()"
                           @on-delete="getPsrType()"
                           v-model="dialogVisible"
                           :psrType="dialogPsrType"></psr-type-modal>
      <psr-type-merge-modal v-model="dialogMergeVisible" :items="mergingItems"
                                  @on-merge="handleMerge($event)"
                                  @on-merge-item-remove="handleMergeItemRemove($event)"></psr-type-merge-modal>
    </div>
  </div>
</template>

<script>
import cloneDeep from 'lodash.clonedeep';
import { mapGetters } from 'vuex';
import psrTypeApi from '@/api/nsi/psrType';
import pagination from '@/components/Ui/Pagination';
import formatters from '@/mixins/formatters.js';
import xlsUpDownHelper from '@/mixins/xlsUpDownHelper';
import PsrTypeModal from './PsrTypeModal';
import PsrTypeMergeModal from './PsrTypeMergeModal';

export default {
  name: 'PsrTypeTable',
  props: {
    filterModel: {
      type: Object,
default: () => {
      }
    },
    filterActive: { type: Boolean, default: () => true },
  },
  mixins: [formatters, xlsUpDownHelper],
  components: { pagination, PsrTypeModal, PsrTypeMergeModal },
  data() {
    return {
      loading: false,
      psrType: [],
      pageNumber: 1,
      pageSize: 50,
      totalCount: 0,
      sortField: 'name',
      sortDesc: false,
      dialogVisible: false,
      dialogPsrType: {},
      mergeMode: false,
      mergingItems: [],
      dialogMergeVisible: false,
    };
  },
  computed: {
    ...mapGetters('identity', ['userHasRole']),
    userCanEdit() {
      return this.userHasRole('NsiCorpEdit,NsiFullAccess');
    },
  },
  watch: {
    filterModel: {
      handler() {
        this.getPsrType();
      },
      deep: true
    },
  },
  async mounted() {
    // this.$nextTick(() => {
    //   this.tableHeight = this.getTableHeight();
    // });
    await this.getPsrType();
   // this.tableHeight = this.getTableHeight();
  },
  methods: {
    async handleDownLoad() {
      this.loading = true;
      const { table } = this.$refs;
      const tableColumns = table.store.states.columns;
      const params = {
        pageNumber: this.pageNumber,
        pageSize: this.totalCount,
        nameFilter: this.filterModel.nameFilter,
        registrationGroupsFilter: this.filterModel.registrationGroupsFilter,
        isLocalNsiFilter: this.filterModel.isLocalNsiFilter,
        sortField: this.sortField,
        sortDesc: this.sortDesc
      };
      const res = await psrTypeApi.getPsrTypesWithPagination(params);
      if (res.data) {
        await this.exportToExcel('Стандартные функции РЗА', res.data.items, tableColumns);
      }
      this.loading = false;
    },
    async getPsrType() {
      this.loading = true;
      const params = {
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        nameFilter: this.filterModel.nameFilter,
        registrationGroupsFilter: this.filterModel.registrationGroupsFilter,
        isLocalNsiFilter: this.filterModel.isLocalNsiFilter,
        sortField: this.sortField,
        sortDesc: this.sortDesc
      };
      const res = await psrTypeApi.getPsrTypesWithPagination(params);
      if (res.data) {
        this.psrType = res.data.items;
        this.totalCount = res.data.totalCount;

        this.applyIsMerging();
      }
      this.loading = false;
    },
    async handleSizeChange() {
      this.pageNumber = 1;
      await this.getPsrType();
    },
    async handleCurrentChange() {
      await this.getPsrType();
    },
    async handleSortChange(event) {
      if (event.order === null) {
        this.sortDesc = null;
        this.sortField = null;
      } else {
        this.sortDesc = event.order === 'descending';
        this.sortField = event.prop;
      }

      await this.getPsrType();
    },
    handleView(index, psrType) {
      this.dialogPsrType = cloneDeep(psrType);
      this.dialogVisible = true;
    },
    handleCreate() {
      this.dialogPsrType = {
        id: null,
        name: null,
        className: '',
        registrationGroupId: '',
        description: '',
        isAip: false,
        actual: true
      };
      this.dialogVisible = true;
      this.$root.$emit('addEvent', {
        editMode: true,
      });
    },
    handleDelete(index, psrType) {
      this.$confirm('Вы действительно хотите удалить запись?', 'Удаление записи!', {
        confirmButtonText: 'ОК',
        cancelButtonText: 'Отмена',
        type: 'warning',
      }).then(async () => {
        this.loading = true;

        const res = await psrTypeApi.deletePsrType(psrType.id);
        if (!res.data.succeeded) {
          this.$alert(res.data.resultMessage, 'Ошибка', {
            confirmButtonText: 'OK',
            type: 'warning',
            customClass: 'alert-30',
          });
        } else {
          await this.getPsrType();
        }

        this.loading = false;
      });
    },
    applyIsMerging() {
      for (let i = 0; i < this.psrType.length; i++) {
        const item = this.psrType[i];
        this.$set(item, 'isMerging', this.mergingItems.some((x) => x.id === item.id));
      }
    },
    handleMergeItemRemove(item) {
      const index = this.mergingItems.findIndex((x) => x.id === item.id);
      if (index > -1) {
        this.mergingItems.splice(index, 1);
        this.applyIsMerging();

        if (this.mergingItems.length <= 1) {
          this.dialogMergeVisible = false;
          this.$alert('Необходимо выбрать 2 или более записей', 'Ошибка', {
            confirmButtonText: 'OK',
            type: 'warning',
            customClass: 'alert-30',
          });
        }
      }
    },
    async handleMerge(data) {
      if (data.secondaryItems.some((x) => x.isAip)) {
        this.$alert('Невозможно объединить несколько корпоративных записей или использовать корпоративную запись в качестве присоединяемой', 'Ошибка', {
          confirmButtonText: 'OK',
          type: 'warning',
          customClass: 'alert-30',
        });
        return;
      }

      this.loading = true;

      const res = await psrTypeApi.mergePsrType(data.mainItem.id, data.secondaryItems.map((x) => x.id));
      if (!res.data.succeeded) {
        this.$alert(res.data.resultMessage, 'Ошибка', {
          confirmButtonText: 'OK',
          type: 'warning',
          customClass: 'alert-30',
        });
      } else {
        this.dialogMergeVisible = false;
        this.handleMergeModeCancel();
        await this.getPsrType();
      }
      this.loading = false;
    },
    handleIsMergingChange(item) {
      if (!this.mergingItems.some((x) => x.id === item.id)) {
        this.mergingItems.push(item);
      } else {
        const index = this.mergingItems.findIndex((x) => x.id === item.id);
        if (index > -1) {
          this.mergingItems.splice(index, 1);
        }
      }
    },
    handleMergeMode() {
      if (!this.mergeMode) {
        this.mergeMode = true;
      } else {
        if (this.mergingItems.length <= 1) {
          this.$alert('Необходимо выбрать 2 или более записей', 'Ошибка', {
            confirmButtonText: 'OK',
            type: 'warning',
            customClass: 'alert-30',
          });
        } else {
          this.dialogMergeVisible = true;
        }
      }
    },
    handleMergeModeCancel() {
      this.mergingItems.splice(0);
      this.applyIsMerging();
      this.mergeMode = false;
    }
  }
};
</script>

<style scoped>

</style>
