<template>
  <el-dialog
    v-if="value"
    :visible.sync="dialogVisible"
    top="2rem"
    width="40%"
    class="nsi-energy-object"
    :close-on-click-modal="false"
  >
    <div slot="title">
      Стандартные функции РЗА
      <template v-if="userCanEdit">
        <el-button v-if="!editMode" class="back_action_panel--first_element" @click="handleEdit" type="text">
          <i class="el-icon-edit"></i> Редактировать
        </el-button>
        <el-button v-if="editMode" class="back_action_panel--first_element custom-button " @click="handleSave" type="text">
           <SaveIcon /> Сохранить
        </el-button>
        <el-button @click="handleDelete(psrType.id)" :disabled="psrType.isAip || psrType.id == null" type="text" icon="el-icon-delete">
          Удалить
        </el-button>
      </template>
   </div>
    <el-form :rules="rules" v-loading="loading" ref="psrTypeForm" :model="psrType" label-width="12rem">
      <el-form-item prop="isAip" label="СК-11:">
        <el-checkbox
          v-model="psrType.isAip"
          :disabled="true">
        </el-checkbox>
      </el-form-item>
      <el-form-item prop="mRid" label="mRid:">
        <el-input
          v-model="psrType.mRid"
          :disabled="!editMode || psrType.isAip">
        </el-input>
      </el-form-item>
      <el-form-item prop="className" label="Наименование класса:">
        <el-select v-model="psrType.className" size="large" filterable clearable :disabled="!editMode || psrType.isAip">
          <el-option
            v-for="item in itemsClassName"
            :key="item"
            :label="item"
            :value="item"
          />
        </el-select>
        <!-- <el-input
          v-model="psrType.className"
          :disabled="!editMode || psrType.isAip">
        </el-input> -->
      </el-form-item>
      <el-form-item prop="name" label="Наименование:">
        <el-input
          id="energy_object-modal--name_input"
          v-model="psrType.name"
          :disabled="!editMode || psrType.isAip">
        </el-input>
      </el-form-item>
      <el-form-item prop="registrationGroupId" label="Группа учёта:">
        <el-select v-model="psrType.registrationGroupId" size="large" filterable clearable :disabled="!editMode || psrType.isAip">
          <el-option
            v-for="item in registrationGroupsWithNotMfu"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="Описание:" prop="description">
        <el-input id="escription" type="textarea"
                  :disabled="!editMode || psrType.isAip" :rows="10"
                  placeholder="" v-model="psrType.description"></el-input>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import SaveIcon from '@/assets/icons/save.svg';
import psrTypeApi from '@/api/nsi/psrType';
import validationRules from '@/mixins/validationRules';

export default {
  name: 'PsrTypeModal',
  props: ['value', 'psrType'],
  mixins: [validationRules],
  computed: {
    ...mapGetters('identity', ['userHasRole']),
    ...mapGetters('dictionaries', ['psrTypePsrTypes', 'registrationGroupsWithNotMfu']),
    userCanEdit() {
      return this.userHasRole('NsiCorpEdit,NsiFullAccess');
    },
    dialogVisible: {
      get() {
        return this.value;
      },
      set(val) {
        if (!val) this.editMode = false;
        this.$emit('input', val);
      },
    },
  },
  components: { SaveIcon },
  data() {
    return {
      editMode: false,
      loading: false,
      rules: {
        name: [
          { required: true, message: 'Поле обязательно для заполнения', trigger: 'blur' },
          { whitespace: true, message: 'Поле обязательно для заполнения', trigger: 'blur' }
        ],
        className: [
          { required: true, message: 'Поле обязательно для заполнения', trigger: 'blur' },
          { whitespace: true, message: 'Поле обязательно для заполнения', trigger: 'blur' }
        ],
        mRid: [ 
          { validator: this.isUUID, trigger: 'blur' }
        ],
        registrationGroupId: [
          { required: true, message: 'Поле обязательно для заполнения', trigger: 'blur' },
        ]
      },
      itemsClassName: ['DisturbanceRecorder', 'ControlAutomation', 
          'RegulatingAutomation', 'EmergencyControlAutomation', 
          'RelayProtection', 'ProtectionEquipment', 
          'LoadSheddingEquipment', 'OperationControlAutomation']
    };
  },
  async mounted() {
    this.$root.$on('addEvent', (x) => {
      this.editMode = x.editMode;
    });
  },
  methods: {
    handleClose() {
      this.dialogVisible = false;
    },
    async addPsrType(psrType) {
      const res = await psrTypeApi.addPsrType(psrType);
      this.$emit('on-create');
      return res;
    },
    async updatePsrType(psrType) {
      const res = await psrTypeApi.updatePsrType(psrType);
      this.$emit('on-update');
      return res;
    },
    async validateForm() {
      const valid = await this.$refs.psrTypeForm.validate();
      return valid;
    },
    async handleSave() {
      const valid = await this.validateForm();
      if (!valid) return;
      const request = this.psrType.id ? this.updatePsrType : this.addPsrType;
      this.loading = true;
      const res =  await request(this.psrType);
      if (!res.data.succeeded) {
          this.$alert(res.data.resultMessage, 'Ошибка', {
            confirmButtonText: 'OK',
            type: 'warning',
          });
      } else {
        this.dialogVisible = false;
      }
      
      this.loading = false;
    },
    handleEdit() {
      this.editMode = true;
    },
    handleDelete(id) {
      this.$confirm('Вы действительно хотите удалить запись?', 'Удаление записи!', {
        confirmButtonText: 'ОК',
        cancelButtonText: 'Отмена',
        type: 'warning',
      }).then(async () => {
        this.loading = true;

        const res = await psrTypeApi.deletePsrType(id);
        if (!res.data.succeeded) {
          this.$alert(res.data.resultMessage, 'Ошибка', {
            confirmButtonText: 'OK',
            type: 'warning',
          });
        } else {
          this.$emit('on-delete');
          this.dialogVisible = false;
        }

        this.loading = false;
      });
    },
  },
};
</script>

<style scoped>

</style>
