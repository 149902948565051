<template>
  <div class="my-flex-container-column">
    <psr-type-filter @active-change="filterActive = $event" :filter-model="filterModel" @clear-filters="clearFilters"/>
    <psr-type-table :filter-active="filterActive" :filter-model="filterModel" />
  </div>
</template>

<script>
import PsrTypeFilter from '@/views/Nsi/PsrType/PsrTypeFilter';
import PsrTypeTable from '@/views/Nsi/PsrType/PsrTypeTable';

export default {
  name: 'PsrType',
  components: { PsrTypeTable, PsrTypeFilter },
  data() {
    return {
      filterModel: {
        nameFilter: '',
        registrationGroupsFilter: [],
        isLocalNsiFilter: false
      },
      filterActive: true
    };
  },
  methods: {
    clearFilters() {
      this.filterModel = {
        nameFilter: '',
        registrationGroupsFilter: [],
        isLocalNsiFilter: false
      };
    }
  }
};
</script>

<style scoped>

</style>
